import type {LoaderFunctionArgs, MetaFunction} from '@remix-run/node'
import {redirect} from '@remix-run/node'
import {useLoaderData} from '@remix-run/react'
import {cx} from 'class-variance-authority'
import {useMemo} from 'react'
import {TimeAgo} from '~/components/TimeAgo'
import {CheckCircleIcon, LinkExternalIcon} from '~/components/icons'
import {invariant} from '~/utils'

interface Metric {
  id: number
  createdAt: string
  status: string
  conclusion: string
  dockerDuration: number
  depotDuration: number
  upstreamCommitMessage: string
}

interface Benchmark {
  repo: string
  scrapeDate: string
  metrics: Metric[]
}

interface LoaderData {
  benchmark: Benchmark
  metadata: RepoMetadata
}
export const meta: MetaFunction<typeof loader> = ({data, params}) => {
  if (!data) return []
  invariant(params.repo, 'params.repo is required')
  const runs = data.benchmark.metrics
  const sortedRuns = sortRuns(runs)
  const avg = computeAverages(sortedRuns)

  const depotDescription = `Depot build is ${speedup(
    avg.dockerDuration,
    avg.depotDuration,
  )} than Docker build for a savings of ${formatSeconds(avg.total_saved)} over the past 10 commits.`
  const depotTitle = `Depot benchmark for ${data.metadata?.upstream}`

  return [
    {title: depotTitle},
    {name: 'description', content: depotDescription},
    {property: 'og:description', content: depotDescription},
    {property: 'og:site_name', content: 'Depot'},
    {property: 'og:locale', content: 'en_US'},
    {property: 'og:type', content: 'website'},
    {property: 'og:title', content: depotTitle},
    {property: 'og:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'og:image:alt', content: depotDescription},
    {property: 'og:url', content: `https://depot.dev/benchmark/${params.repo}`},
    {property: 'twitter:description', content: depotDescription},
    {property: 'twitter:title', content: depotTitle},
    {property: 'twitter:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'twitter:card', content: 'summary_large_image'},
    {property: 'twitter:site', content: '@depotdev'},
  ]
}

export async function loader({params}: LoaderFunctionArgs) {
  invariant(params.repo, 'params.repo is required')
  const res = await fetch('https://depot.github.io/scrape/results.json')
  const data: Benchmark[] = await res.json()

  const benchmark = data.find((benchmark) => benchmark.repo === `benchmark-${params.repo}`)
  if (!benchmark) throw redirect('/')

  const metadata = repoMetadata[params.repo]
  if (!metadata) throw redirect('/')

  return {benchmark, metadata} satisfies LoaderData
}

function formatSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secondsRemainder = Math.floor(seconds % 60)
  const parts = []
  if (hours) parts.push(`${hours}h`)
  if (minutes) parts.push(`${minutes}m`)
  if (secondsRemainder) parts.push(`${secondsRemainder}s`)
  return parts.join(' ')
}

interface RepoMetadata {
  name: string
  description: string
  upstream: string
  multiArch?: boolean
}

export const repoMetadata: Record<string, RepoMetadata | undefined> = {
  chamber: {
    name: 'Chamber',
    description:
      'Chamber is a tool for managing secrets. Currently it does so by storing secrets in SSM Parameter Store, an AWS service for storing secrets.',
    upstream: 'segmentio/chamber',
    multiArch: true,
  },

  // chatwoot: {
  //   name: 'Chatwoot',
  //   description:
  //     'Chatwoot is an open-source, self-hosted customer engagement suite. Chatwoot lets you view and manage your customer data, communicate with them irrespective of which medium they use, and re-engage them based on their profile.',
  //   upstream: 'chatwoot/chatwoot',
  // },

  dispatch: {
    name: 'Dispatch',
    description:
      'Dispatch is an open source, self-hosted crisis management orchestration framework created by Netflix. Dispatch helps you effectively manage security incidents by deeply integrating with existing tools used throughout an organization (Slack, GSuite, Jira, etc.,) Dispatch is able to leverage the existing familiarity of these tools to provide orchestration instead of introducing another tool.',
    upstream: 'Netflix/dispatch',
  },

  flagsmith: {
    name: 'Flagsmith',
    description:
      'Flagsmith is an open source, fully featured, Feature Flag and Remote Config service. Flagsmith makes it easy to create and manage features flags across web, mobile, and server side applications. Just wrap a section of code with a flag, and then use Flagsmith to toggle that feature on or off for different environments, users or user segments.',
    upstream: 'Flagsmith/flagsmith',
  },

  mastodon: {
    name: 'Mastodon',
    description:
      'Mastodon is a free, open-source social network server based on ActivityPub where users can follow friends and discover new ones. On Mastodon, users can publish anything they want: links, pictures, text, video. All Mastodon servers are interoperable as a federated network.',
    upstream: 'mastodon/mastodon',
    multiArch: true,
  },

  moby: {
    name: 'Moby',
    description:
      'Moby is an open-source project created by Docker to enable and accelerate software containerization. It provides a "Lego set" of toolkit components, the framework for assembling them into custom container-based systems, and a place for all container enthusiasts and professionals to experiment and exchange ideas.',
    upstream: 'moby/moby',
  },

  novu: {
    name: 'Novu',
    description:
      "The ultimate service for managing multi-channel notifications with a single API. Novu's goal is to simplify notifications and provide developers with the tools to create meaningful communication between the system and its users.",
    upstream: 'novuhq/novu',
  },

  outline: {
    name: 'Outline',
    description:
      'A fast, collaborative, knowledge base for your team built using React and Node.js. Beautiful, feature rich, and markdown compatible.',
    upstream: 'outline/outline',
  },

  'plausible-analytics': {
    name: 'Plausible Analytics',
    description:
      'Plausible Analytics is a simple, lightweight (< 1 KB), open-source and privacy-friendly alternative to Google Analytics. It doesn’t use cookies and is fully compliant with GDPR, CCPA and PECR.',
    upstream: 'plausible/analytics',
  },

  posthog: {
    name: 'PostHog',
    description:
      'PostHog is an open-source product analytics suite, built for engineers. Automatically track every event on your website or app, understand your users and how to improve your product, deploy on your own infrastructure to keep control of your data.',
    upstream: 'PostHog/posthog',
  },

  restic: {
    name: 'Restic',
    description:
      'restic is a backup program that is fast, efficient and secure. It supports the three major operating systems (Linux, macOS, Windows) and a few smaller ones (FreeBSD, OpenBSD).',
    upstream: 'restic/restic',
  },

  supabase: {
    name: 'Supabase',
    description:
      'Supabase is an open source Firebase alternative. Start your project with a Postgres database, Authentication, instant APIs, Edge Functions, Realtime subscriptions, and Storage.',
    upstream: 'supabase/supabase',
    multiArch: true,
  },

  temporal: {
    name: 'Temporal',
    description:
      'Temporal is a microservice orchestration platform which enables developers to build scalable applications without sacrificing productivity or reliability. Temporal server executes units of application logic, Workflows, in a resilient manner that automatically handles intermittent failures, and retries failed operations.',
    upstream: 'temporalio/temporal',
    multiArch: true,
  },
}

export function speedup(a: number, b: number) {
  if (a < b) {
    return `${Math.round(((b - a) / a) * 100)}% slower`
  }

  if (a / b > 2) {
    return `${Math.round((a / b) * 10) / 10}x faster`
  }

  return `${Math.round(((a - b) / a) * 100)}% faster`
}

function sortRuns(runs: Metric[]) {
  const sorted = runs.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
  const last10 = sorted.length > 10 ? sorted.slice(sorted.length - 10) : sorted
  return last10.reverse()
}

function computeAverages(sortedRuns: Metric[]) {
  const avg = sortedRuns.reduce(
    (acc, curr) => {
      acc.depotDuration += curr.depotDuration
      acc.dockerDuration += curr.dockerDuration
      return acc
    },
    {depotDuration: 0, dockerDuration: 0},
  )
  return {
    depotDuration: avg.depotDuration / sortedRuns.length,
    dockerDuration: avg.dockerDuration / sortedRuns.length,
    total_saved: avg.dockerDuration - avg.depotDuration,
  }
}

export default function Page() {
  const {benchmark, metadata} = useLoaderData<typeof loader>()

  const runs = benchmark.metrics

  const sortedRuns = useMemo(() => sortRuns(runs), [runs])

  const avg = useMemo(() => computeAverages(sortedRuns), [sortedRuns])

  return (
    <div className="mx-auto w-full max-w-7xl space-y-4 px-4 py-8 pb-8 md:px-0">
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col justify-between gap-4 md:w-1/3">
          <div className="flex-1 space-y-2 rounded-lg bg-radix-mauve1 p-8">
            <div className="leading-none text-radix-mauve11">GitHub Actions Benchmark</div>
            <h1 className="text-3xl font-bold leading-none">{metadata.name}</h1>
            <a
              href={`https://github.com/${metadata.upstream}`}
              className="block text-xl font-medium leading-none text-radix-purple11"
              target="_blank"
              rel="noreferrer"
            >
              {metadata.upstream} <LinkExternalIcon className="inline-block h-4 w-4 align-middle" />
            </a>
            <div className="max-w-xl text-sm text-radix-mauve11">{metadata.description}</div>
            {metadata.multiArch && (
              <div className="max-w-xl text-sm text-radix-yellow11">
                This is a multi-architecture benchmark, it built an Intel (linux/amd64) and Arm (linux/arm64) image.
              </div>
            )}
          </div>
          <div className="space-y-1 rounded-lg bg-radix-mauve1 p-8 text-sm">
            <div className="text-radix-mauve11">
              With <code className="rounded bg-radix-indigo5 px-1 text-radix-indigo11">depot build</code>, the past 10
              runs were
            </div>
            <div className="text-2xl font-bold tracking-tight text-radix-grass11">
              {speedup(avg.dockerDuration, avg.depotDuration)}
            </div>

            <div className="text-radix-mauve11">saving a total of</div>
            <div className="text-2xl font-bold tracking-tight text-radix-grass11">{formatSeconds(avg.total_saved)}</div>
            <div className="text-radix-mauve11">
              compared to <code className="rounded bg-radix-indigo5 px-1 text-radix-indigo11">docker build</code>.
            </div>
          </div>

          <div className="flex flex-1 items-center rounded-lg bg-radix-yellow1 p-8 text-sm">
            <div>
              <div className="font-semibold text-radix-yellow11">About the benchmark</div>
              <div className="text-radix-yellow12">
                For every commit to {metadata.upstream}, GitHub Actions runs one workflow job building the project with
                the <code>docker/build-push-action</code> action and caching enabled and one workflow job building the
                project with <code>depot build</code>. The time each job takes is recorded and visualized here.
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 rounded-lg bg-radix-mauve1 px-4 py-8 text-radix-mauve11 md:w-2/3">
          <div className="px-4 leading-none text-radix-mauve11">Last 10 GitHub Actions Runs</div>

          <div className="overflow-hidden">
            {sortedRuns.map((run) => (
              <a
                key={run.id}
                href={`https://github.com/depot/${benchmark.repo}/actions/runs/${run.id}`}
                className="block rounded-lg px-4 py-2 hover:bg-radix-mauve3 md:flex md:items-start"
              >
                <div className="flex flex-1 items-start">
                  <CheckCircleIcon className="mr-4 mt-1 block h-6 w-6 text-radix-grass9" />
                  <div>
                    <div className="max-w-md truncate text-radix-mauve12">{run.upstreamCommitMessage}</div>
                    <TimeAgo dateTime={run.createdAt} title={run.createdAt} className="text-xs" />
                  </div>
                </div>
                <div className="ml-8 w-60 min-w-fit space-y-1 whitespace-nowrap text-sm text-radix-mauve11 md:ml-0">
                  <div className="">
                    <div className="inline-block w-14">
                      <span className="font-semibold">Depot</span>
                    </div>{' '}
                    {formatSeconds(run.depotDuration)}{' '}
                    {Math.round((run.dockerDuration / run.depotDuration) * 10) / 10 !== 0 && (
                      <>
                        /{' '}
                        <span
                          className={cx(
                            'text-sm',
                            run.dockerDuration > run.depotDuration ? 'text-radix-grass11' : 'text-radix-red11',
                          )}
                        >
                          {speedup(run.dockerDuration, run.depotDuration)}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="">
                    <div className="inline-block w-14">
                      <span className="font-semibold">Docker</span>
                    </div>{' '}
                    {formatSeconds(run.dockerDuration)}
                  </div>
                </div>
              </a>
            ))}
          </div>
          <a
            href={`https://github.com/depot/${benchmark.repo}/actions/workflows/benchmark.yml`}
            className="block border-t border-radix-mauve6 pt-6 text-right text-sm leading-none hover:text-radix-mauve12"
            target="_blank"
            rel="noreferrer"
          >
            View all runs <LinkExternalIcon className="inline-block h-4 w-4 align-middle" />
          </a>
        </div>
      </div>

      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-1 flex-col gap-4 rounded-lg bg-radix-mauve1 p-8 text-radix-mauve11">
          <div className="text-xl text-radix-mauve12">Docker Workflow</div>

          <div className="flex-1 whitespace-pre font-mono text-sm">
            <span className="text-radix-grass11">jobs</span>:<br />
            {'  '}
            <span className="text-radix-grass11">benchmark-docker</span>:
            <br />
            {'    '}
            <span className="text-radix-grass11">name</span>:{' '}
            <span className="text-radix-blue11">Build with Docker</span>
            <br />
            {'    '}
            <span className="text-radix-grass11">runs-on</span>: <span className="text-radix-blue11">ubuntu-20.04</span>
            <br />
            {'    '}
            <span className="text-radix-grass11">steps</span>:
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">actions/checkout@v3</span>
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">docker/setup-buildx-action@v2</span>
            {metadata.multiArch && (
              <>
                <br />
                {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
                <span className="text-radix-blue11">docker/setup-qemu-action@v2</span>
              </>
            )}
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">docker/build-push-action@v2</span>
            <br />
            {'        '}
            <span className="text-radix-grass11">with</span>:
            <br />
            {'          '}
            <span className="text-radix-grass11">tags</span>:{' '}
            <span className="text-radix-blue11">depot/{benchmark.repo}:benchmark</span>
            {!metadata.multiArch && (
              <>
                <br />
                {'          '}
                <span className="text-radix-grass11">cache-from</span>:{' '}
                <span className="text-radix-blue11">type=gha</span>
                <br />
                {'          '}
                <span className="text-radix-grass11">cache-to</span>:{' '}
                <span className="text-radix-blue11">type=gha,mode=max</span>
              </>
            )}
            {metadata.multiArch && (
              <>
                <br />
                {'          '}
                <span className="text-radix-grass11">platforms</span>:{' '}
                <span className="text-radix-blue11">linux/amd64,linux/arm64</span>
              </>
            )}
            <br />
          </div>

          <a
            href={`https://github.com/depot/${benchmark.repo}/blob/HEAD/.github/workflows/benchmark.yml#L11-L23`}
            className="block border-t border-radix-mauve6 pt-6 text-right text-sm leading-none hover:text-radix-mauve12"
            target="_blank"
            rel="noreferrer"
          >
            View workflow source <LinkExternalIcon className="inline-block h-4 w-4 align-middle" />
          </a>
        </div>
        <div className="flex flex-1 flex-col gap-4 rounded-lg bg-radix-mauve1 p-8 text-radix-mauve11">
          <div className="text-xl text-radix-mauve12">Depot Workflow</div>

          <div className="flex-1 whitespace-pre font-mono text-sm">
            <span className="text-radix-grass11">jobs</span>:<br />
            {'  '}
            <span className="text-radix-grass11">benchmark-depot</span>:
            <br />
            {'    '}
            <span className="text-radix-grass11">name</span>:{' '}
            <span className="text-radix-blue11">Build with Depot</span>
            <br />
            {'    '}
            <span className="text-radix-grass11">runs-on</span>: <span className="text-radix-blue11">ubuntu-20.04</span>
            <br />
            {'    '}
            <span className="text-radix-grass11">steps</span>:
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">actions/checkout@v3</span>
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">depot/setup-action@v1</span>
            <br />
            {'      '}- <span className="text-radix-grass11">uses</span>:{' '}
            <span className="text-radix-blue11">depot/build-push-action@v1</span>
            <br />
            {'        '}
            <span className="text-radix-grass11">with</span>:
            <br />
            {'          '}
            <span className="text-radix-grass11">tags</span>:{' '}
            <span className="text-radix-blue11">depot/{benchmark.repo}:benchmark</span>
            {metadata.multiArch && (
              <>
                <br />
                {'          '}
                <span className="text-radix-grass11">platforms</span>:{' '}
                <span className="text-radix-blue11">linux/amd64,linux/arm64</span>
              </>
            )}
            <br />
          </div>

          <a
            href={`https://github.com/depot/${benchmark.repo}/blob/HEAD/.github/workflows/benchmark.yml#L25-L36`}
            className="block border-t border-radix-mauve6 pt-6 text-right text-sm leading-none hover:text-radix-mauve12"
            target="_blank"
            rel="noreferrer"
          >
            View workflow source <LinkExternalIcon className="inline-block h-4 w-4 align-middle" />
          </a>
        </div>
      </div>
    </div>
  )
}
